import styles from "./styles.module.scss"

type CarouselPagingIndicatorProps = {
  activePage: number
  numberOfPages: number
  handleChangePage: (newValue: number) => void
}

function CarouselPagingIndicator({
  activePage, numberOfPages, handleChangePage
}: CarouselPagingIndicatorProps) {

  const backArrowIsEnabled = activePage > 0
  const nextArrowIsEnabled = activePage < numberOfPages - 1

  function handleBack() {
    if (backArrowIsEnabled) {
      handleChangePage(activePage - 1)
    }
  }

  function handleNext() {
    if (nextArrowIsEnabled) {
      handleChangePage(activePage + 1)
    }
  }

  function handleChangeToIndex(index: number) {
    if (index !== activePage) {
      handleChangePage(index)
    }
  }

  return (
    <div className={styles.container}>
      <img
        src={`/assets/carousel/carousel_arrow_left_${backArrowIsEnabled ? "enabled" : "disabled"}.svg`}
        alt="Voltar"
        onClick={handleBack}
        className={backArrowIsEnabled ? styles.arrowEnabled : styles.arrowDisabled}
      />

      {Array.from(Array(numberOfPages), (_, index) => (
        <div
          key={index}
          className={`${styles.item} ${index === activePage && styles.itemActive}`}
          onClick={() => handleChangeToIndex(index)}
        />
      ))}

      <img
        src={`/assets/carousel/carousel_arrow_right_${nextArrowIsEnabled ? "enabled" : "disabled"}.svg`}
        alt="Avançar"
        onClick={handleNext}
        className={nextArrowIsEnabled ? styles.arrowEnabled : styles.arrowDisabled}
      />
    </div>
  )
}

export { CarouselPagingIndicator }
