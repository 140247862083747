import { RecipesCarouselGridSection } from "../RecipesCarouselGridSection"
import { useBreakpoints } from "hooks/useScreen"
import { RecipeSectionProps } from "../types"

import styles from "./styles.module.scss"

function Recipe3GridSection(props: RecipeSectionProps) {

  const { isDesktop } = useBreakpoints()

  const columns = isDesktop ? 3 : 2.2
  const space = isDesktop ? 32 : 16
 
  return (
    <RecipesCarouselGridSection 
      {...props}
      recipeType="adaptive"
      columns={columns}
      recipeImageClass={styles.recipeImage}
      spaceBetweenItens={space}
    />
  )
}

export { Recipe3GridSection }