import { GetStaticProps } from "next"
import { useRouter } from "next/router";
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import i18nConfigs from "../../next-i18next.config.js"

import { getHomeSections } from "services/home/homeService";

import { SubSection } from "components/shared/SubSection";
import { HomeSEO } from "components/Home/seo";
import { HightlightSection } from "components/Home/HighlightSection";
import { HomeConfigurableEvent, HomeSection, RecipeSectionProps } from "components/Home/types";

import { useLocale } from "hooks/useLocale";

import { timeInSeconds } from "utils/timeUtils";
import { REVALIDATE_DEFAULT_MINUTES } from "utils/constants";
import { getLocale, navigationRoutePath, pathResolverCategory } from "utils/routes";
import { Log } from "utils/log";
import { 
  sendCategoryClick, sendUrlClick
} from "utils/events/defaults";
import { 
  sendHomeMarketplaceButtonClick, sendHomeUtensilClick
} from "utils/events/homeEvents";

import styles from "components/Home/styles.module.scss";
import { useAuth } from "hooks/useAuth";
import { BannersSection } from "components/Home/BannersSection/index";
import { ChefsCarousel } from "components/shared/ChefsCarousel/index";
import { ShowcaseCarouselSection } from "components/Home/ShowcaseCarouselSection/index";
import { Recipe3GridSection } from "components/Home/Recipe3GridSection/index";
import { Recipe4GridSection } from "components/Home/Recipe4GridSection/index";
import { Recipe6GridSection } from "components/Home/Recipe6GridSection/index";
import { CategoriesCarousel } from "components/shared/CategoriesCarousel/index";
import { PageRoute, navigationRoutes, routeResolve } from "services/router/routerService";
import { ButtonsHome } from "Partials/Header/components/ButtonsHome/index";
import { Content } from "Partials/Content/index";

type HomeProps = {
  sections: HomeSection[]
  data: {
    navigationRoutes: PageRoute[]
  }
}

function Home({ sections, data: { navigationRoutes } }: HomeProps) {

  const router = useRouter()
  const { locale } = useLocale()
  const { user, isLoadingUser, handleActionOrOpenLogin } = useAuth()

  function handleOpenSendRecipePage() {
    handleActionOrOpenLogin(user => {
      if (user) {
        router.push(navigationRoutePath(router, "send-recipe", navigationRoutes))
      }
    })
  }

  function handleOpenMyProlifePage() {
    handleActionOrOpenLogin(user => {
      if (user) {
        router.push(`/${user?.username}`)
      }
    })
  }

  function handleCategoryClick(categoryId: string, section: HomeSection, extraParams?: object) {
    const category = section.categories!.find(category => category.id === categoryId)
    sendCategoryClick({
      source: "home",
      source_context: section.type,
      category_id: categoryId,
      home_section_position: section.position,
      ...extraParams
    })
    router.push(pathResolverCategory(router, category!.paths))
  }

  function handleUtensilClick(utensilUrl: string, section: HomeSection, extraParams?: object) {
    sendHomeUtensilClick({
      source: "home",
      source_context: section.type,
      utensil_url: utensilUrl,
      home_section_position: section.position,
      ...extraParams
    })
  }

  function handleMarketplaceButtonClick(section: HomeSection) {
    sendHomeMarketplaceButtonClick({
      source: "home",
      source_context: section.type,
      home_section_position: section.position
    })
  }

  function handleLogEventClickEvent(section: HomeSection, event: HomeConfigurableEvent) {
    logConfigurableEvent(section, event)
  }

  function handleLogActionClickEvent(section: HomeSection, event: HomeConfigurableEvent) {
    logConfigurableEvent(section, event, true)
  }

  function logConfigurableEvent(
    section: HomeSection, event: HomeConfigurableEvent, isFromAction?: boolean
  ) {
    let extraParams = undefined
    if (isFromAction) {
      extraParams = {
        home_section_see_all: true
      }
    }

    switch (event.object_type) {
      case "category":
        handleCategoryClick(event.object_id, section, extraParams)
        break;
      case "link":
        sendUrlClick({
          source: "home",
          source_context: section.type,
          url: event.object_id,
          home_section_position: section.position,
          ...extraParams
        })
        break;
    }
  }

  function homeSection(section: HomeSection, index: number) {
    if (section.countries) {
      const country = user?.country ?? locale?.country
      if (country && !isLoadingUser) {
        if (!section.countries.includes(country)) {
          return null
        }
      } else {
        return null
      }
    }

    let component: JSX.Element | undefined
    switch (section.type) {
      case "highlight":
        component = <HightlightSection
          key={index}
          highlight={section.highlight!}
          onClickEvent={() => handleLogEventClickEvent(section, section.highlight!.event)}
          onActionClickEvent={() => handleLogActionClickEvent(section, section.highlight!.action.event)}
        />
        break
      case "banners":
        component = <BannersSection
          banners={section.banners!}
          onClickEvent={(event) => handleLogEventClickEvent(section, event)}
          onActionClickEvent={(event) => handleLogActionClickEvent(section, event)}
        />
        break
      case "chefs":
        component = <ChefsCarousel 
          chefs={section.chefs!}
          source="home"
          source_context={section.type}
        />
        break
      case "showcase":
        component = <ShowcaseCarouselSection
          utensils={section.showcase!}
          onUtensilClick={(url) => handleUtensilClick(url, section)}
          onMarketplaceButtonClick={() => handleMarketplaceButtonClick(section)}
        />
        break
      case "categories":
        component = <CategoriesCarousel
          categories={section.categories!}
          onCategoryClick={(categoryId) => handleCategoryClick(categoryId, section)}
        />
        break
      case "recipes":
        const params: RecipeSectionProps = {
          recipes: section.recipes!.items,
          source: "home",
          source_context: section.type,
        }

        switch (section.recipes!.grid_size) {
          case 3:
            component = <Recipe3GridSection {...params} />
            break
          case 4:
            component = <Recipe4GridSection {...params} />
            break
          case 6:
            component = <Recipe6GridSection {...params} /> 
            break
        }
        break
    }

    if (!component) {
      return <div key={index}></div>
    }

    if (section.title) {
      return (
        <SubSection 
          key={index}
          title={section.title!}
          description={section.description}
          descriptionNumberOfLines={1}
          see_all={section.see_all}
          onActionClickEvent={(event) => handleLogActionClickEvent(section, event)}
        >
          {component}
        </SubSection>
      )
    }
    return component
  }

  return (
    <>
      <HomeSEO/>
      <Content className={styles.sections} navigationRoutes={navigationRoutes}>
        <ButtonsHome 
          navigationRoutes={navigationRoutes}
          handleOpenSendRecipePage={handleOpenSendRecipePage}
          handleOpenMyProlifePage={handleOpenMyProlifePage} 
        />
        {sections.map(homeSection).filter(section => section)}
      </Content>
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  try {
    const _locale = getLocale(locale)
    
    const [sections, pathData, routes] = await Promise.all([
      getHomeSections(_locale),
      routeResolve(""),
      navigationRoutes()
    ])

    return {
      props: {
        ...(await serverSideTranslations(_locale, ["common"], i18nConfigs)),
        sections: sections.data,
        data: {
          pathData,
          navigationRoutes: routes
        }
      },
      revalidate: timeInSeconds({ minutes: REVALIDATE_DEFAULT_MINUTES })
    }
  } catch(error: any) {
    Log.error(error)
    throw error
  }
}

export default Home