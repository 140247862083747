import { RecipesCarouselGridSection } from "../RecipesCarouselGridSection"
import { useBreakpoints } from "hooks/useScreen"
import { RecipeSectionProps } from "../types"

import styles from "./styles.module.scss"

function Recipe6GridSection(props: RecipeSectionProps) {

  const { isDesktop } = useBreakpoints()

  const columns = isDesktop ? 3 : 1.1
  const rows = isDesktop ? 2 : 1
  const space = isDesktop ? 26 : 20
 
  return (
    <RecipesCarouselGridSection 
      {...props}
      recipeType="dataInCover"
      columns={columns}
      rows={rows}
      recipeImageClass={styles.recipeImage}
      spaceBetweenItens={space}
    />
  )
}

export { Recipe6GridSection }