import { useTranslation } from "next-i18next"

import { HorizontalGrid } from "components/shared/HorizontalGrid"
import { OutlineButton } from "components/shared/Buttons/OutlineButton"

import { useBreakpoints } from "hooks/useScreen"
import { SHOWCASE_SEE_ALL } from "utils/constants"

import { UtensilItem } from "./UtensilItem"

import styles from "./styles.module.scss"

type ShowcaseCarouselSectionProps = {
  utensils: ShowcaseItem[]
  onUtensilClick: (utensilUrl: string) => void
  onMarketplaceButtonClick: () => void
}

type ShowcaseItem = {
  title: string
  price: number
  image: string
  url: string
}

function ShowcaseCarouselSection({
  utensils, onUtensilClick, onMarketplaceButtonClick
}: ShowcaseCarouselSectionProps) {

  const { isDesktop } = useBreakpoints()
  const { t } = useTranslation("common")

  const columns = isDesktop ? 5 : 2.2
  const space = 16

  return (
    <div className={styles.container}>
      <HorizontalGrid
        columns={columns}
        spaceBetweenItens={space}
        className={styles.utensils}
      >
        {utensils.map((utensil, index) => {
          return (
            <UtensilItem 
              key={index}
              {...utensil}
              onUtensilClick={onUtensilClick}
            />
          )
        })}
      </HorizontalGrid>
      <OutlineButton size="mini">
        <a
          href={SHOWCASE_SEE_ALL}
          onClick={onMarketplaceButtonClick}
          target={"_blank"}
        >
          {t("recipe.showcase_see_more")}
        </a>
      </OutlineButton>
    </div>
  )
}

export { ShowcaseCarouselSection }