import { DeliRecPages } from "utils/routes"
import { EventActions, EventNames } from "."
import { sendEvent } from "."

type UtensilClickParams = {
  [key:string]: any
  source: string
  source_context: string
  utensil_url: string
}

function sendHomeUtensilClick(params: UtensilClickParams) {
  sendEvent({
    name: EventNames.home_marketplace_buy,
    action: EventActions.click,
    params: params
  })
}

type MarketplaceButtonClickParams = {
  [key:string]: any
  source: string
  source_context: string
}

function sendHomeMarketplaceButtonClick(params: MarketplaceButtonClickParams) {
  sendEvent({
    name: EventNames.home_marketplace,
    action: EventActions.click,
    params: params
  })
}

export { 
  sendHomeUtensilClick, sendHomeMarketplaceButtonClick
}