import { useRef, useState } from "react"

import { CarouselPagingIndicator } from "../CarouselPagingIndicator"
import { HorizontalGrid, IHorizontalGridRef } from "components/shared/HorizontalGrid"
import { RecipeItemAdaptive, RecipeItemDataInCover } from "components/shared/RecipeItem"
import { useBreakpoints } from "hooks/useScreen"
import { IRecipeItem } from "types/recipe"
import { DeliRecPages } from "utils/routes"

import styles from "./styles.module.scss"

type RecipesCarouselGridSectionProps = {
  rows?: number
  columns: number
  recipes: IRecipeItem[]
  recipeImageClass: string
  recipeType: "dataInCover" | "adaptive"
  spaceBetweenItens?: 8 | 16 | 20 | 26 | 32
  source: DeliRecPages
  source_context: string
}

async function sleep() {
  return new Promise((resolve, _) => setTimeout(resolve, 100))
}

function RecipesCarouselGridSection({
  rows = 1, columns, recipes, recipeImageClass, spaceBetweenItens, 
  recipeType, source, source_context
}: RecipesCarouselGridSectionProps) {

  const { isDesktop } = useBreakpoints()

  const sliderRef = useRef<IHorizontalGridRef>(null)
  const [activeItem, setActiveItem] = useState(0)

  async function handleChangePage(pageIndex: number) {
    if (isDesktop) {
      if (pageIndex % columns !== 0) {
        await sleep()
        if (pageIndex !== (recipes.length / rows) - columns) {
          sliderRef.current?.slickGoTo(Math.round(pageIndex / columns) * columns)
        } else {
          setActiveItem(Math.ceil(pageIndex / columns))
        }
      } else {
        setActiveItem(Math.ceil(pageIndex / columns))
      }
    }
  }

  function handleManualChangePage(page: number) {
    setActiveItem(page)
    sliderRef.current?.slickGoTo(page * columns)
  }

  return (
    <div className={styles.container}>
      <HorizontalGrid 
        sliderRef={sliderRef}
        rows={rows}
        columns={columns}
        handleChangePage={handleChangePage}
        spaceBetweenItens={spaceBetweenItens}
      >
        {
          recipes.map(recipe => {
            if (recipeType === "dataInCover") {
              return <RecipeItemDataInCover 
                key={recipe.id}
                {...recipe}
                className={recipeImageClass}
                source={source}
                source_context={source_context}
              />
            } 
            return <RecipeItemAdaptive 
                key={recipe.id}
                {...recipe}
                className={recipeImageClass}
                source={source}
                source_context={source_context}
              />
          })
        }
      </HorizontalGrid>
      {
        isDesktop && (
          <CarouselPagingIndicator 
            activePage={activeItem}
            numberOfPages={Math.ceil(recipes.length / rows / columns)}
            handleChangePage={handleManualChangePage}
          />
        )
      }
    </div>
  )
}

export { RecipesCarouselGridSection }