import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import styles from "./styles.module.scss"

type UtensilItemProps = {
  url: string
  image: string
  title: string
  price: number|string
  onUtensilClick: (utensilUrl: string) => void
}

function UtensilItem({ url, image, title, price, onUtensilClick }: UtensilItemProps) {

  const { t } = useDelirecTranslation()

  return (
    <a 
      href={url}
      className={styles.utensil}
      onClick={() => onUtensilClick(url)}
      target={"_blank"}
    >
      <div className={styles.imagemContainer}>
        <img src={image} alt={title} />
      </div>
      <p className={styles.title}>{title}</p>
      <p className={styles.price}>
        {
          typeof price === "string"
            ? price
            : Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL"
              }).format(price)
        }
      </p>
      <button className={styles.buyButton}>
        {t("buttonActions.buy")}
      </button>
    </a>
  )
}

export { UtensilItem }
