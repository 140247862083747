import { useEffect, useRef, useState } from "react"
import { useTranslation } from "next-i18next"

import { useBreakpoints } from "hooks/useScreen"
import { i18nText } from "utils/i18nUtils"
import { HorizontalGrid, IHorizontalGridRef } from "../HorizontalGrid"

import styles from "./styles.module.scss"

type CategoriesCarouselProps = {
  categories: CategoryItem[]
  onCategoryClick: (categoryId: string) => void
}

type CategoryItem = {
  id: string
  en?: string
  pt: string
}

const NOT_SPACE = undefined
const CATEGORIES_MOBILE_SPACE_BETWEEN_ITENS = 26

const CATEGORIES_DESKTOP_COLUMNS = 6
const CATEGORIES_DESKTOP_ROWS = 1

const CATEGORIES_MOBILE_COLUMNS = 3.5
const CATEGORIES_MOBILE_ROWS = 2

function CategoriesCarousel({ categories, onCategoryClick }: CategoriesCarouselProps) {

  const { i18n } = useTranslation("common");
  const { isDesktop } = useBreakpoints()

  const categoriesColumns = isDesktop ? CATEGORIES_DESKTOP_COLUMNS : CATEGORIES_MOBILE_COLUMNS
  const categoriesRows = isDesktop ? CATEGORIES_DESKTOP_ROWS : CATEGORIES_MOBILE_ROWS
  const space = isDesktop ? NOT_SPACE : CATEGORIES_MOBILE_SPACE_BETWEEN_ITENS

  const gridRef = useRef<IHorizontalGridRef>(null)
  const [hasPrevious, setHasPrevious] = useState(false)
  const [hasNext, setHasNext] = useState(true)
  const [pageIndex, setPageIndex] = useState(0)

  function handleNextPage() {
    gridRef.current?.slickNext()
  }

  function handlePrevPage() {
    gridRef.current?.slickPrev()
  }

  useEffect(() => {
    if (categories) {
      if (pageIndex === 0) {
        setHasPrevious(false)
        setHasNext((categories.length / categoriesRows) > categoriesColumns)
      } else {
        setHasPrevious(true)
        setHasNext(pageIndex < (categories.length / categoriesRows) - categoriesColumns)
      }
    }
  }, [pageIndex, categories, categoriesColumns])

  return (
    <div className={styles.container}>
      <HorizontalGrid
        columns={categoriesColumns}
        rows={categoriesRows}
        handleChangePage={setPageIndex}
        sliderRef={gridRef}
        withBorder
        spaceBetweenItens={space}
        className={styles.mobileGrid}
      >
        {
          categories.map((category, index) => {
            const name = i18nText(i18n, category)

            return (
              <div key={index} className={`${styles.categoryItem}`}>
                <div onClick={() => onCategoryClick(category.id)}>
                  <img src={`/assets/categories/${category.id}_on.svg`} alt={name} />
                  <p>{name}</p>
                </div>
              </div>
            )
          })
        }
      </HorizontalGrid>
      {
        isDesktop && (
          <>
            {
              hasPrevious && (
                <img
                  src="/assets/carousel/list_left_arrow.svg"
                  alt="Voltar lista"
                  className={styles.leftArrow}
                  onClick={handlePrevPage}
                />
              )
            }
            {
              hasNext && (
                <img
                  src="/assets/carousel/list_right_arrow.svg"
                  alt="Avançar lista"
                  className={styles.rightArrow}
                  onClick={handleNextPage}
                />
              )
            }
          </>
        )
      }
    </div>
  )
}

export { CategoriesCarousel }