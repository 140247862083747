import { HorizontalGrid } from "components/shared/HorizontalGrid"
import { RecipeBannerItem } from "components/shared/RecipeBannerItem"
import { useBreakpoints } from "hooks/useScreen"
import { BannerItem, HomeConfigurableEvent } from "../types"

import styles from "./styles.module.scss"

type BannersSectionProps = {
  banners: BannerItem[]
  onClickEvent: (event: HomeConfigurableEvent) => void
  onActionClickEvent: (event: HomeConfigurableEvent) => void
}

function BannersSection(props: BannersSectionProps) {

  const { isDesktop } = useBreakpoints()

  const { banners } = props

  const height = isDesktop ? 328 : 290
  const columns = isDesktop ? banners.length : banners.length === 1 ? 1 : 2.2
  const space = isDesktop ? 26 : 16

  return (
    <div className={styles.container}>
      <HorizontalGrid
        columns={columns}
        spaceBetweenItens={space}
      >
        {
          banners.map((banner, index) => (
            <RecipeBannerItem 
              key={index}
              height={height}
              {...banner}
              {...props}
            />
          ))
        }
      </HorizontalGrid>
    </div>
  )
}

export { BannersSection }
