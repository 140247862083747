import { useTranslation } from "next-i18next"
import { HomeConfigurableEvent } from "components/Home/types"

import { MultiLanguageString } from "types/utils"
import { i18nText } from "utils/i18nUtils"

import styles from "./styles.module.scss"
import { Link } from "../Link"

type RecipeBannerAction = {
  prefix: MultiLanguageString
  text: MultiLanguageString
  link: string
  event: HomeConfigurableEvent
}

type RecipeBannerItemProps = {
  height: number
  title: MultiLanguageString
  subtitle: MultiLanguageString
  link: string
  image: string
  event: HomeConfigurableEvent
  action: RecipeBannerAction
  onClickEvent: (event: HomeConfigurableEvent) => void
  onActionClickEvent: (event: HomeConfigurableEvent) => void
}

function RecipeBannerItem({ 
  title, subtitle, link, image, action, height, 
  event, onClickEvent, onActionClickEvent
}: RecipeBannerItemProps) {

  const { i18n } = useTranslation("common");

  const i18nTitle = i18nText(i18n, title)
  const i18nSubtitle = i18nText(i18n, subtitle)
  const actionPrefix = i18nText(i18n, action.prefix)
  const actionText = i18nText(i18n, action.text)

  return (
    <div className={styles.container} style={{height: `${height}px`}}>
      <div onClick={() => onClickEvent(event)} >
        <Link href={link} className={styles.imageContainer}>
          <img src={image} alt="" className={styles.image}/>
          <div className={styles.background} />
        </Link>
      </div>
      <div className={styles.content}>
        <p className={styles.subtitle}>{i18nSubtitle}</p>
        <p className={styles.title}>{i18nTitle}</p>
        <div className={styles.actionContainer}>
          <p className={styles.actionPrefix}>{actionPrefix}&nbsp;</p>
          <div onClick={() => onActionClickEvent(action.event)}>
            <Link href={action.link} className={styles.actionLink}>
              {actionText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export { RecipeBannerItem }