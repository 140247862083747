import { useDelirecTranslation } from "hooks/useDelirecTranslation";
import Head from "next/head"
import { useRouter } from "next/router"

const default_og_image = 'https://assets.delirec.com/corp%2Flogo_transparente_azul.png';

function HomeSEO() {

  const router = useRouter()
  const { t } = useDelirecTranslation()

  return (
    <Head>
      <title>{t("home.seo_title")}</title>
      <meta
        name="description"
        content={t("home.seo_description")}
      />
      <meta
        name="keywords"
        content={t("home.keywords")}
      />
      <meta
        property="og:title"
        content={t("home.seo_title")}
      />
      <meta
        property="og:description"
        content={t("home.seo_description")}
      />
      <meta
        property="og:url"
        content={router.asPath}
      />
      <meta
        property="og:image"
        content={default_og_image}
      />
      <meta
        name="twitter:title"
        content={t("home.seo_title")}
      />
      <meta
        name="twitter:description"
        content={t("home.seo_description")}
      />
      <meta
        name="twitter:url"
        content={router.asPath}
      />
      <meta
        name="twitter:image"
        content={default_og_image}
      />
    </Head>
  )
}

export { HomeSEO }