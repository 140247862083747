import { useRouter } from "next/router"
import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import styles from "./styles.module.scss"
import { useNavigationRoutePath } from "utils/routes"
import { PageRoute } from "services/router/routerService"

type ButtonsHomeProps = {
  navigationRoutes: PageRoute[]
  handleOpenSendRecipePage: () => void
  handleOpenMyProlifePage: () => void
}

const ButtonsHome = ({
  navigationRoutes, handleOpenSendRecipePage, handleOpenMyProlifePage
}: ButtonsHomeProps) => {
  const { t } = useDelirecTranslation()
  const router = useRouter()
  const myMenuPath = useNavigationRoutePath(router, "my-menu", navigationRoutes)

  const handleNavigate = (route: string) =>  {
    router.push(route)
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <button
          className={styles.button}
          type="button"
          onClick={handleOpenSendRecipePage}
        >
          <img className={styles.plus} src="/assets/shared/icon_plus.svg" alt={t("buttonActions.sendRecipe")}/>
          <span>{t("buttonActions.sendRecipe")}</span>
        </button>
        <button
          className={styles.button}
          type="button"
          onClick={() => handleNavigate(`/${myMenuPath}`)}
        >
          <img className={styles.calendar} src="/assets/shared/icon_calendar.svg" alt={t("myMenu.page.breadcrumbs")}/>
          <span>{t("buttonActions.my_menu")}</span>
        </button>
        <button
          className={`${styles.button} ${styles.buttonUser}`}
          type="button"
          onClick={handleOpenMyProlifePage}
        >
          <img className={styles.user} src="/assets/shared/icon_user.svg" alt={t("header.my_profile")}/>
          <span>{t("header.my_profile")}</span>
        </button>
      </div>

    </div>
  )
}

export { ButtonsHome }