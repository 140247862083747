import { useDelirecTranslation } from "hooks/useDelirecTranslation"
import { useRouter } from "next/router"
import { useUserEventPath } from "utils/events/utils"
import { chefUrl, DeliRecPages } from "utils/routes"
import { Link } from "../Link"
import styles from "./styles.module.scss"

type ChefItemProps = {
  id: string
  name?: string
  photo?: string
  username: string
  source: DeliRecPages
  source_context: string
}

function ChefItem({ name, photo, username, source, source_context }: ChefItemProps) {

  const { t } = useDelirecTranslation()
  const router = useRouter()

  const url = useUserEventPath(chefUrl(username, router), source, source_context)

  return (
    <Link 
      href={url}
      className={styles.container}
    >
      <div 
        className={`${styles.image}`}
      >
        <img src={photo} alt={t("home.chef_alt", { name })} />
      </div>
      <p>{name}</p>
    </Link>
  )
}

export { ChefItem }