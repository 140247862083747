import { Link } from "components/shared/Link"
import { useTranslation } from "next-i18next"

import { i18nText } from "utils/i18nUtils"

import { HighlightSection } from "../types"

import styles from "./styles.module.scss"

type HightlightSectionProps = {
  highlight: HighlightSection
  onClickEvent: () => void
  onActionClickEvent: () => void
}

function HightlightSection({ highlight, onClickEvent, onActionClickEvent }: HightlightSectionProps) {

  const { i18n, t } = useTranslation("common");

  const title = i18nText(i18n, highlight.title)
  const description = i18nText(i18n, highlight.description)
  const actionPrefix = i18nText(i18n, highlight.action.prefix)
  const actionText = i18nText(i18n, highlight.action.text)

  return (
    <div className={styles.container}>
      <div
        className={styles.imageContainer}
        onClick={onClickEvent}
      >
        <Link href={highlight.link}>
          <img src={highlight.image} alt={t("home.highlight_alt", { name: title })} className={styles.image}/>
        </Link>
      </div>
      <div className={styles.content}>
        <div>
          <Link href={highlight.link} className={styles.title}>
            {title}
          </Link>
          <p className={styles.description}>
            {description}
          </p>
          <div className={styles.chefUsername}>
            <p>{actionPrefix}&nbsp;</p>
            <div onClick={onActionClickEvent}>
              <Link href={highlight.action.link}>
                {actionText}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { HightlightSection }