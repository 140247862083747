import { useEffect, useRef, useState } from "react"

import { HorizontalGrid, IHorizontalGridRef } from "components/shared/HorizontalGrid"

import { useBreakpoints } from "hooks/useScreen"

import styles from "./styles.module.scss"
import { ChefItem } from "../ChefItem"
import { DeliRecPages } from "utils/routes"

type IChefItem = {
  id: string
  name: string
  photo?: string
  username: string
  bio: string
}

type ChefsCarouselProps = {
  chefs: IChefItem[]
  source: DeliRecPages
  source_context: string
}

const CHEFS_DESKTOP_COLUMNS = 5
const CHEFS_DESKTOP_SPACE_BETWEEN = 20

const CHEFS_MOBILE_COLUMNS = 4.3
const CHEFS_MOBILE_SPACE_BETWEEN = 8

function ChefsCarousel({ chefs, source, source_context }: ChefsCarouselProps) {

  const { isDesktop } = useBreakpoints()

  const chefsColumns = isDesktop ? CHEFS_DESKTOP_COLUMNS : CHEFS_MOBILE_COLUMNS
  const space = isDesktop ? CHEFS_DESKTOP_SPACE_BETWEEN :  CHEFS_MOBILE_SPACE_BETWEEN

  const gridRef = useRef<IHorizontalGridRef>(null)

  const [hasPrevious, setHasPrevious] = useState(false)
  const [hasNext, setHasNext] = useState(true)
  const [pageIndex, setPageIndex] = useState(0)

  function handleNextPage() {
    gridRef.current?.slickNext()
  }

  function handlePrevPage() {
    gridRef.current?.slickPrev()
  }

  useEffect(() => {
    if (chefs) {
      if (pageIndex === 0) {
        setHasPrevious(false)
        setHasNext(chefs.length > chefsColumns)
      } else {
        setHasPrevious(true)
        setHasNext(pageIndex < chefs.length - chefsColumns)
      }
    }
  }, [pageIndex, chefs])

  return (
    <div className={styles.container}>
      <HorizontalGrid
        columns={chefsColumns}
        handleChangePage={setPageIndex}
        sliderRef={gridRef}
        spaceBetweenItens={space}
      >
        {
          chefs.map((chef, index) => (
            <ChefItem 
              key={index}
              {...chef}
              source={source}
              source_context={source_context}
            />
          ))
        }
      </HorizontalGrid>
      {
        isDesktop && (
          <>
            {
              hasPrevious && (
                <img
                  src="/assets/carousel/list_left_arrow.svg"
                  alt="Voltar lista"
                  className={styles.leftArrow}
                  onClick={handlePrevPage}
                />
              )
            }
            {
              hasNext && (
                <img
                  src="/assets/carousel/list_right_arrow.svg"
                  alt="Avançar lista"
                  className={styles.rightArrow}
                  onClick={handleNextPage}
                />
              )
            }
          </>
        )
      }
    </div>
  )
}

export { ChefsCarousel }